import React, {
  useContext,
  useEffect,
  useState,
  lazy,
  Suspense,
  useMemo,
  useCallback,
  useReducer
} from 'react'
import { Form, message, Spin } from 'antd'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { endOfDay, isAfter, subDays } from 'date-fns'
// import { useRecoilValue } from 'recoil'

import './Actions.css'
import { appConfig } from '../../../../../constants/appConfig'
import { ActionButton, ActionForm } from '../../index'
import { CandidatePanelContext } from '../../../../../contexts/candidatePanelContext'
import { useMutateFlowAction, useMutateFlowReuseCandidate } from '../../../../../api/flow/candidate'
import { CANDIDATES_PAGE } from '../../../../../constants/routes'
import {
  getDepartmentListPropsByCandidate,
  getScheduleProps,
  isActionSameState
} from '../../../../../helpers/action'
import { UserContext } from '../../../../../contexts/userContext'
import { /*areArraysIntersect,*/ simpleReducer } from '../../../../../helpers'
import { UtmModal } from '../UtmModal'
import { useReferences } from '../../../../../hooks/useReferences'
// import { modules } from '../../../../../constants'
// import { useConfigContextData } from '../../../../../hooks/useConfigContextData'
import { formatLocale, getOffset } from '../../../../../helpers/date-fns'
import { useVacancies } from '../../../../../hooks/useVacancies'
import ReuseModalForm from '../../ReuseModalForm/ReuseModalForm'
import { AppointmentContext } from '../../../../../contexts/appointmentContext'
// import { customPropertiesSettingsAtom } from '../../../../../recoil/atoms'

const CommonActions = lazy(() => import('./CommonActions'))

function Actions({ isSearch }) {
  const [utmForm] = Form.useForm()
  const navigate = useNavigate()
  const [action, setAction] = useState(undefined)
  const [closeProfile, setCloseProfile] = useState(false)
  const [isReuseModalVisible, setIsReuseModalVisible] = useState(false)
  const { candidate, showPanelTab } = useContext(CandidatePanelContext)
  const { user, isHiringManager } = useContext(UserContext)
  // const { moduleName } = useRecoilValue(customPropertiesSettingsAtom)
  const { isActiveVacancy, vacancies } = useVacancies()
  const showInListsVacancies = useMemo(() => vacancies?.filter(v => v.showInLists), [vacancies])
  const { appointment, setAppointment } = useContext(AppointmentContext)
  // const { getDepartmentSingularName } = useConfigContextData()
  // const isValidRole = useMemo(
  //   () => isHiringManager || isManager || isRecruiter || isAdmin,
  //   [isHiringManager, isManager, isRecruiter, isAdmin]
  // )
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [reuseData, setReuseData] = useState(undefined)
  const [utms, setUtms] = useReducer(simpleReducer, {
    requestType: null,
    utm_medium: null
  })

  useReferences({
    requestTypes: true,
    utms: true,
    utmsQuery: utms.requestType
  })

  // Метод апи для работы с flow actions
  const { mutate: mutateFlowAction, isLoading: isLoadingFlowAction } = useMutateFlowAction()

  // Отправка данных в апи
  const postAction = useCallback(
    async (act, payload) => {
      const data = {
        candidateId: candidate._id,
        ...payload
      }
      const path = (act.prefixUrl || appConfig.workflows.candidate.prefixUrl) + act.name

      await mutateFlowAction(
        { path, data },
        {
          onSuccess: dataFlowAction => {
            if (!closeProfile) {
              // не закрываем дровер но возвращаем на основную информацию
              setAction(undefined)
            } else {
              // При успешном флоу переходим на соответствующий роут текущей роли.
              if (isHiringManager) {
                setAppointment?.({})

                // В случае удачного флоу директора, для некоторых статусов не нужен переход на список
                const noRedirectStatuses = [
                  appConfig.statuses.values.interviewScheduled,
                  appConfig.statuses.values.cameForAnInterview
                ]
                if (noRedirectStatuses.includes(dataFlowAction.data?.candidate?.state?.name)) {
                  setAction(undefined)
                } else {
                  navigate(CANDIDATES_PAGE)
                }
              } else navigate(CANDIDATES_PAGE)
            }
          },
          onError: () => message.error('Ошибка при переводе кандидата в другой статус')
        }
      )
    },
    [candidate?._id, mutateFlowAction, navigate, isHiringManager, closeProfile, setAppointment]
  )

  const {
    mutate: reuseCandidate,
    isLoading: isLoadingReuseCandidate,
    isError: isErrorReuseCandidate,
    isSuccess: isSuccessReuseCandidate
  } = useMutateFlowReuseCandidate()

  useEffect(() => {
    if (isErrorReuseCandidate) {
      setAction(undefined)
      message.error('Ошибка переиспользования кандидата')
    }
    if (isSuccessReuseCandidate) {
      setAction(undefined)
      message.success('Кандидат успешно переиспользован')
    }
  }, [isErrorReuseCandidate, isSuccessReuseCandidate])

  const handleReuseCandidate = useCallback(
    async passedAction => {
      const { requestType, utm_source, utm_medium } = utmForm.getFieldsValue()
      const data = {
        requestType: requestType || null,
        utm_source: utm_source || null,
        utm_medium: utm_medium || null,
        utm_campaign: null,
        utm_term: null
      }
      setIsModalVisible(false)

      if (isActiveVacancy(candidate.vacancy?._id)) {
        if ((action || passedAction).currentStep) {
          setReuseData(data)
        } else {
          await reuseCandidate({ id: candidate?._id, data })
        }
        utmForm.setFieldsValue({
          requestType: null,
          utm_medium: null,
          utm_source: null
        })
      } else {
        return setIsReuseModalVisible(true)
      }
    },
    [utmForm, candidate, reuseCandidate, isActiveVacancy, action]
  )

  const handleReuseCandidateWithActiveVacancy = useCallback(
    async values => {
      const { requestType, utm_source, utm_medium } = utmForm.getFieldsValue()
      const data = {
        requestType: requestType || null,
        utm_source: utm_source || null,
        utm_medium: utm_medium || null,
        utm_campaign: null,
        utm_term: null,
        vacancy: values?.vacancy
      }
      if (action.currentStep) {
        setReuseData(data)
      } else {
        await reuseCandidate({ id: candidate?._id, data })
      }

      setIsReuseModalVisible(false)
      utmForm.setFieldsValue({
        requestType: null,
        utm_medium: null,
        utm_source: null
      })
    },
    [reuseCandidate, candidate, utmForm, action]
  )

  const handleReuse = useCallback(
    action => {
      // TODO: сделать через стэпсы
      if (user.agency) handleReuseCandidate(action)
      else setIsModalVisible(true)
    },
    [handleReuseCandidate, user.agency]
  )

  // Обработчик нажатия на кнопку экшена
  const handleAction = useCallback(
    (action, payload) => {
      setCloseProfile(action?.appearance?.autoClose)
      if (action?.name === appConfig.workflows.candidate.actions.reuse && !reuseData) {
        setReuseData({})
        setAction(action)
        handleReuse(action)
        return
      }
      if (action?.executeImmediately) return postAction(action, {})
      if (!action) {
        setAction(undefined)
      } else if (!payload) {
        setAction(action)
      } else {
        postAction(
          action,
          action?.name === appConfig.workflows.candidate.actions.reuse
            ? { ...payload, ...reuseData }
            : payload
        )
      }
    },
    [postAction, handleReuse, reuseData]
  )

  const handleSetRequestType = useCallback(
    value => {
      setUtms({
        requestType: value,
        utm_medium: null
      })
      utmForm.setFieldsValue({
        utm_medium: null,
        utm_source: null
      })
    },
    [utmForm]
  )
  const handleSetUtmMedium = useCallback(
    value => {
      setUtms({
        ...utms,
        utm_medium: value
      })
      utmForm.setFieldsValue({
        utm_source: null
      })
    },
    [utmForm]
  )

  // Непосредственная отрисовка кнопки экшена. Набор кнопок лежит в соответствующем роли компоненте (_ROLE_Actions)
  const renderButton = useCallback(
    pureAction => {
      // const message = isActionSameState(action.name, candidate.state?.name)
      //   ? changeDepartmentStates.includes(action.name)
      //     ? `В другой ${getDepartmentSingularName()?.toLowerCase()}`
      //     : 'Перепланировать собеседование'
      //   : action.message
      let action = { ...pureAction }
      const message =
        candidate?.possibleActions?.find(a => a.name === action.name)?.displayName || '###'
      const candidateActions = isSearch
        ? candidate?.possibleActions
            ?.filter(
              action => action.name === appConfig.workflows.candidate.actions.reuse
              // && areArraysIntersect(action.roles, user.roles)
            )
            ?.map(action => action?.name) || []
        : candidate?.possibleActions
            // ?.filter(action => areArraysIntersect(action.roles, user.roles))
            ?.map(action => action?.name) || []

      // if (
      //   isCrewmanCandidate &&
      //   moduleName === modules.bk &&
      //   action.name === appConfig.workflows.candidate.actions.sendToMedicalTest
      // ) {
      //   action.executeImmediately = false
      // }
      if (candidateActions?.includes(action.name)) {
        // const actionData = {
        //   ...action,
        //   roles: candidate?.possibleActions?.find(action => action.name === action.name)?.roles
        // }
        let handleOnAction = () => handleAction(action, null)
        // Для БК:
        // Для ЧБР кандидата при нажатии на кнопку "Оформить медосмотр" переводим на вкладку МедОсмотра
        if (action?.currentStep === appConfig.workflows.steps.other.showPanel) {
          handleOnAction = () =>
            showPanelTab?.(
              action.steps?.find(step => step.name === appConfig.workflows.steps.other.showPanel)
                ?.panelKey
            )
        }
        // TODO: need check
        if (isHiringManager) {
          if (
            [
              appConfig.workflows.candidate.actions.scheduleInterview,
              appConfig.workflows.candidate.actions.rescheduleInterview
            ]?.includes(action.name)
          ) {
            handleOnAction = () =>
              handleAction(
                {
                  ...action,
                  // для переназначения времени собеседования в РИ
                  interviewerDepartmentId:
                    candidate?.application?.interviewerDepartment?._id || null,
                  department: appointment.department
                    ? { _id: appointment.department?._id || appointment.department }
                    : user?.department
                  // currentStep: appConfig.workflows.steps.interviews.schedulerCustom
                },
                null
              )
          }
          // if (appConfig.workflows.candidate.actions.changeDepartment === action.name) {
          //   handleOnAction = () =>
          //     handleAction(
          //       {
          //         ...action,
          //         department: null
          //         // currentStep: appConfig.workflows.steps.interviews.departmentsListWithPlans
          //       },
          //       null
          //     )
          // }
        } else {
          if (appConfig.workflows.candidate.actions.rescheduleInterview === action.name) {
            action.department = candidate?.application?.department
          }
          if (
            [
              appConfig.workflows.candidate.actions.scheduleInterview,
              appConfig.workflows.candidate.actions.rescheduleInterview
            ]?.includes(action.name)
          ) {
            action.interviewerDepartmentId =
              candidate?.application?.interviewerDepartment?._id || null
          }
        }
        // можем вручную добавить вызов календаря
        if (action?.currentStep === appConfig.workflows.steps.interviews.schedulerCustom) {
          if (candidate?.application) {
            handleOnAction = () =>
              handleAction(
                {
                  ...action,
                  // для переназначения времени собеседования в РИ
                  interviewerDepartmentId:
                    candidate?.application?.interviewerDepartment?._id || null,
                  department: appointment.department
                    ? { _id: appointment.department?._id || appointment.department }
                    : user?.department
                },
                null
              )
          } else {
            action.executeImmediately = true
            handleOnAction = () => handleAction(action, null)
          }
        }
        return (
          <ActionButton key={action.name} {...action} message={message} onAction={handleOnAction} />
        )
      }
    },
    [
      appointment,
      candidate?.application,
      isHiringManager,
      user?.department,
      candidate?.possibleActions,
      // candidate.state?.name,
      handleAction,
      isSearch,
      // user.roles,
      // isAmpCandidate,
      // isCrewmanCandidate,
      showPanelTab
      // moduleName
      // getDepartmentSingularName
    ]
  )

  // Для экшенов Интервью используется календарь. Футер календаря с кнопками.
  const renderFooter = (action, payload) => {
    const { start, end } = payload.events?.find(e => e.eventType === 'reserve') || {}
    const offset =
      action?.department?.plan?.interviewerDepartment?.timeOffset ||
      action?.department?.timeOffset ||
      getOffset(formatLocale(new Date(), 'XXX'))
    const startDate = dayjs(start).utcOffset(offset, true).utc()

    const data = {
      /**
       * Чтобы передать в бэк время в UTC, учитываем timeOffset
       * Если в экшене не указан департамент, значит собеседование хотят в локальном времени компьютера
       * Пример: "Интервью с HR" для АУП. У юзера нет департамента и его никто не выбирает,
       * значит собеседование по локальному времени юзера
       */
      interview: {
        start: startDate?.format(),
        end: dayjs(end).utcOffset(offset, true).utc().format(),
        color: action.appearance?.appointmentColor
      },
      department: action?.interviewerDepartmentId
        ? candidate?.application?.department?._id || candidate?.application.department
        : action.department?._id || candidate?.application?.department?._id
      // color: action.appearance?.appointmentColor,
      // interviewStart: startDate?.format(),
      // interviewEnd: dayjs(end).utcOffset(offset, true).utc().format(),
      // ...(action.department?._id && {
      //   // если есть РИ то РР надо брать из кандидата так как в экшине будет РИ
      //   departmentId: action?.interviewerDepartmentId
      //     ? candidate?.application?.department?._id || candidate?.application.department
      //     : action.department._id,
      //   interviewerDepartmentId:
      //     action?.interviewerDepartmentId ||
      //     action.department?.plan?.interviewerDepartment?._id ||
      //     action.department?.plan?.interviewerDepartment
      // })
    }

    return (
      <Spin spinning={isLoadingFlowAction || isLoadingReuseCandidate} size="large">
        <div className="btn-wrap mt-3">
          <ActionButton
            {...action}
            message="Записать"
            appearance={{
              ...action?.appearance,
              fill: true,
              color: 'green'
            }}
            onAction={() => handleAction(action, data)}
            disabled={!start || isAfter(endOfDay(subDays(new Date(), 1)), new Date(startDate))}
          />
          {/*{action.department?._id && (*/}
          <ActionButton
            {...action}
            message="Назад"
            appearance={{ ...action?.appearance, color: 'red', fill: false }}
            onAction={() => {
              action?.steps?.findIndex(e => e.name === action?.currentStep) === 0
                ? // если это первый шаг то сбрасываем действия
                  handleAction(null, null)
                : // переходим к предыдущему шагу
                  handleAction(
                    {
                      ...action,
                      currentStep:
                        action?.steps?.[
                          action?.steps?.findIndex(e => e.name === action?.currentStep) - 1
                        ]?.name
                    },
                    null
                  )
              setReuseData(undefined)
            }}
          />
          {/*)}*/}
        </div>
      </Spin>
    )
  }
  // Общие для всех экшенов Интервью пропсы календаря и формы выбора ресторана
  const scheduleProps = getScheduleProps({ footer: renderFooter })
  const departmentListProps = getDepartmentListPropsByCandidate(candidate)
  const handleCloseModal = useCallback(() => {
    setIsReuseModalVisible(false)
    setIsModalVisible(false)
    setAction(undefined)
    setReuseData(undefined)
  }, [])

  // if (!isValidRole) return null
  return (
    <div className="recruiter-actions mt-3">
      {action ? (
        <ActionForm
          action={action}
          candidate={candidate}
          onAction={handleAction}
          {...scheduleProps}
          {...departmentListProps}
          interview={
            !isActionSameState(action.name, candidate.state?.name) && candidate?.state?.interview
          }
        />
      ) : (
        <Spin spinning={isLoadingFlowAction || isLoadingReuseCandidate} size="large">
          <Suspense fallback={<></>}>
            {/*{(isRecruiter || isPartner || isTU || isOperationDirector || isAdmin) && (*/}
            <CommonActions renderButton={renderButton} />
            {/*)}*/}
            {/*{isHiringManager && (*/}
            {/*  <HiringManagerActions renderButton={renderButton} handleAction={handleAction} />*/}
            {/*)}*/}
          </Suspense>
        </Spin>
      )}
      <UtmModal
        open={isModalVisible}
        onOk={handleReuseCandidate}
        onCancel={handleCloseModal}
        form={utmForm}
        requestType={utms.requestType}
        utmMedium={utms.utm_medium}
        onRequestTypeChange={handleSetRequestType}
        onUtmMediumChange={handleSetUtmMedium}
      />

      <ReuseModalForm
        list={showInListsVacancies}
        loading={isLoadingReuseCandidate}
        isVisible={isReuseModalVisible}
        onClose={handleCloseModal}
        onOk={handleReuseCandidateWithActiveVacancy}
      />
    </div>
  )
}
export default Actions
